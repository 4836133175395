import { lazy } from 'react';

import { withSuspenseFallback } from '../../../helpers/withSuspenseFallback';

const Borders = lazy(() => import('../pages/borders/Borders'));
const Categories = lazy(() => import('../pages/categories/Categories'));
const Flavors = lazy(() => import('../pages/flavors/Flavors'));
const Ingredients = lazy(() => import('../pages/ingredients/Ingredients'));
const Totens = lazy(() => import('../pages/totens/Totens'));
const ConfigTotem = lazy(() => import('../pages/config-totem/ConfigTotem'));

const baseRoute = '/pizzas';

const pizzasRoutes = [
  { path: `${baseRoute}/borders`, component: withSuspenseFallback(Borders) },
  { path: `${baseRoute}/categories`, component: withSuspenseFallback(Categories) },
  { path: `${baseRoute}/flavors`, component: withSuspenseFallback(Flavors) },
  { path: `${baseRoute}/ingredients`, component: withSuspenseFallback(Ingredients) },
  { path: `${baseRoute}/totens`, component: withSuspenseFallback(Totens) },
  { path: `${baseRoute}/config-totem`, component: withSuspenseFallback(ConfigTotem) },
];

export default pizzasRoutes;

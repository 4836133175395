import i18next from 'i18next';

const ratingsSystemConfig = () => ({
  title: i18next.t('ratingSystem.sidebar'),
  path: '/products/ratings-system',
  childrensub: [
    // {title:"DashBoard", path: '/products/ratings-system/dashboard'},
    { title: i18next.t('ratingSystem.reports.title'), path: '/products/ratings-system/reports' },
  ],
});

export default ratingsSystemConfig;

import artworkConfig from '../features/products/artwork/routes/sidebarConfig';
import pizzasConfig from '../features/products/pizzas/routes/sidebarConfig';
import pricePanelConfig from '../features/products/price-panel/routes/sidebarConfig';
import ratingSystemConfig from '../features/products/rating-system/routes/sidebarConfig';
import ticketSystemConfig from '../features/products/ticket-system/routes/sidebarConfig';

import userPermissions from './userPermissions';

const productsConfig = () => {
  const productsConfigArr = [];

  if (userPermissions.artwork) {
    productsConfigArr.push(artworkConfig);
  }
  if (userPermissions.pizzas) {
    productsConfigArr.push(pizzasConfig);
  }
  if (userPermissions.pricePanel) {
    productsConfigArr.push(pricePanelConfig);
  }
  if (userPermissions.ratingSystem) {
    productsConfigArr.push(ratingSystemConfig());
  }
  if (userPermissions.ticketSystem) {
    productsConfigArr.push(ticketSystemConfig);
  }

  return productsConfigArr;
};

export default productsConfig;

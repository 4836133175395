// ARTWORK
import basePTLanguage from '../i18n/translation/pt.json';
import baseENLanguage from '../i18n/translation/en.json';
import baseESLanguage from '../i18n/translation/es.json';

// SISTEMA AVALIAÇÃO
import ratingSystemPT from '../features/products/rating-system/translation/pt.json';
import ratingSystemEN from '../features/products/rating-system/translation/en.json';
import ratingSystemES from '../features/products/rating-system/translation/es.json';

import userPermissions from './userPermissions';

export const ptLanguage = basePTLanguage;
export const enLanguage = baseENLanguage;
export const esLanguage = baseESLanguage;

if (userPermissions.ratingSystem) {
  ptLanguage.translation.ratingSystem = ratingSystemPT;
  enLanguage.translation.ratingSystem = ratingSystemEN;
  esLanguage.translation.ratingSystem = ratingSystemES;
}

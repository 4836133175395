import axios from 'axios';

const token = localStorage.getItem('token');


export const baseURL = window.env.REACT_APP_URL;


export const baseURLFile = window.env.REACT_APP_URL;


const api = axios.create({
  baseURL: `${baseURL}/api`,
});

// eslint-disable-next-line dot-notation
api.defaults.headers.common['Authorization'] = token;

export default api;

const pizzasConfig = {
  title: 'Pizzas',
  path: '/products/pizzas',
  childrensub: [
    { title: 'Categorias', path: '/products/pizzas/categories' },
    { title: 'Sabores', path: '/products/pizzas/flavors' },
    { title: 'Ingredientes', path: '/products/pizzas/ingredients' },
    { title: 'Bordas', path: '/products/pizzas/borders' },
    { title: 'Totens', path: '/products/pizzas/totens' },
    { title: 'Config Totem Pizza', path: '/products/pizzas/config-totem' },
  ],
};

export default pizzasConfig;

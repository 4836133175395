import { Outlet } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import Page from '../components/Page';

export default function BaseLayout({ loading }) {
  return (
    <Page contentstyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}>
      {loading && <LinearProgress />}
      <Outlet />
    </Page>
  );
}

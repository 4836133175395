const ticketSystemConfig = {
  title: 'Sistema de Senhas',
  path: '/products/ticket-system',
  childrensub: [
    {
      title: 'Relatórios',
      path: '/products/ticket-system',
    },
  ],
};

export default ticketSystemConfig;

import { lazy } from 'react';
import { withSuspenseFallback } from '../../../helpers/withSuspenseFallback';

const PanelCategories = lazy(() => import('../pages/categories/Categories'));
const PanelProducts = lazy(() => import('../pages/products/Products'));
const Prices = lazy(() => import('../pages/prices/Prices'));
const Terminals = lazy(() => import('../pages/terminal/Terminals'));

const baseRoute = '/price-panel';

const pricePanelRoutes = [
  {
    path: `${baseRoute}/categories`,
    component: withSuspenseFallback(PanelCategories),
  },
  {
    path: `${baseRoute}/products`,
    component: withSuspenseFallback(PanelProducts),
  },
  {
    path: `${baseRoute}/prices`,
    component: withSuspenseFallback(Prices),
  },
  {
    path: `${baseRoute}/terminals`,
    component: withSuspenseFallback(Terminals),
  },
];

export default pricePanelRoutes;

import { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Tooltip } from '@mui/material';
//
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Icon } from '@iconify/react';
// import arrowDownward from '@iconify/icons-eva/arrow-ios-downward-fill';
// import arrowForwards from '@iconify/icons-eva/arrow-ios-forward-fill';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  // color: theme.palette.secondary.main,
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  fontSize: 16,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ListSubChildStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(9),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

// ----------------------------------------------------------------------

function SubNavItem({ child2, active }) {
  const { pathname } = useLocation();

  const isActiveSubItem = pathname.includes(child2.path);

  const { title, path, childrensub, accessible } = child2;
  const [open2, setOpen2] = useState(isActiveSubItem);
  const [pathFinal, setPathFinal] = useState(path);

  const handleOpenChild = () => {
    setOpen2((prev) => !prev);
  };

  const activeChildSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
  };

  useLayoutEffect(() => {
    if (accessible === 1) setPathFinal(path);
    else setPathFinal('#');
    // console.log(`Acessível ${pathFinal}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div key={title}>
      <ListItemStyle
        component={RouterLink}
        key={child2.title}
        onClick={handleOpenChild}
        to={pathFinal}
        sx={{
          ...(isActiveSubItem && activeChildSubStyle),
          position: 'relative',
        }}
      >
        <ListItemIconStyle>
          <Box
            component="span"
            sx={{
              width: 4,
              height: 4,
              display: 'flex',
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'text.disabled',
              transition: (theme) => theme.transitions.create('transform'),
              ...(isActiveSubItem && {
                transform: 'scale(2)',
                bgcolor: 'primary.main',
              }),
            }}
          />
        </ListItemIconStyle>
        <ListItemText disableTypography primary={title} />

        <Box
          component={Icon}
          icon={open2 ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16, position: 'absolute', right: '15%' }}
        />
      </ListItemStyle>

      <Collapse in={open2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childrensub.map((childsub) => {
            const { title, path, icon } = childsub;
            const isActiveChildSub = active(path);

            return (
              <ListSubChildStyle
                key={title}
                component={RouterLink}
                to={path}
                sx={{
                  ...(isActiveChildSub && activeChildSubStyle),
                }}
              >
                <ListItemIconStyle>
                  {icon || (
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveChildSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  )}
                </ListItemIconStyle>
                <ListItemText disableTypography primary={title} />
              </ListSubChildStyle>
            );
          })}
        </List>
      </Collapse>
    </div>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, isdraweropen, handledraweropen }) {
  const isActiveRoot = active(item.path);

  const { title, path, icon, children, accessible } = item;
  const [open, setOpen] = useState(isActiveRoot);

  useLayoutEffect(() => {
    if (!isdraweropen) setOpen(false);
  }, [isdraweropen]);

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'bold',
  };

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'bold',
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          component={RouterLink}
          onClick={() => {
            if (!isdraweropen) handledraweropen();
            handleOpen();
          }}
          to={path && accessible && path}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            position: 'relative',
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} sx={{ marginLeft: 1 }} />
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, position: 'absolute', right: isdraweropen ? '25%' : 0 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {open &&
              children.map((child2) => {
                const { title, path, childrensub } = child2;
                const isActiveSub = active(path);

                return (
                  <div key={title}>
                    {childrensub ? (
                      <SubNavItem child2={child2} to={path} active={active} />
                    ) : (
                      <ListItemStyle
                        component={RouterLink}
                        to={path}
                        sx={{
                          ...(isActiveSub && activeSubStyle),
                        }}
                      >
                        <ListItemIconStyle>
                          <Box
                            component="span"
                            sx={{
                              width: 4,
                              height: 4,
                              display: 'flex',
                              borderRadius: '50%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'text.disabled',
                              transition: (theme) => theme.transitions.create('transform'),
                              ...(isActiveSub && {
                                transform: 'scale(2)',
                                bgcolor: 'primary.main',
                              }),
                            }}
                          />
                        </ListItemIconStyle>
                        <ListItemText disableTypography primary={title} />
                      </ListItemStyle>
                    )}
                  </div>
                );
              })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      onClick={() => item.subMenu && handledraweropen(true)}
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

      {isdraweropen && <ListItemText disableTypography primary={title} />}
    </ListItemStyle>
  );
}

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => pathname.includes(path);

  const { isdraweropen, handledraweropen } = other;

  return (
    <Box sx={{ paddingY: 3 }}>
      <List
        sx={{
          p: 0.5,
          // p: !isdraweropen && 0.5,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        {navConfig.map((item) => (
          <Tooltip key={item.path} title={!isdraweropen && item.title} placement="right" disableInteractive>
            <Box>
              <NavItem
                item={item}
                active={match}
                isdraweropen={isdraweropen}
                handledraweropen={handledraweropen}
                key={item.path}
              />
            </Box>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
}

import { lazy } from 'react';

import { withSuspenseFallback } from '../../../helpers/withSuspenseFallback';

const Repository = lazy(() => import('../../../../pages/Repository'));
const PdfView = lazy(() => import('../../../../pages/PdfView'));
const Projects = lazy(() => import('../../../../pages/Projects'));
const Workflow = lazy(() => import('../../../../pages/Workflow'));
const Timeline = lazy(() => import('../../../../pages/Timeline'));
// const Config = lazy(() => import('../../../../pages/Config'));
const Tags = lazy(() => import('../../../../pages/Tags'));
// const User = lazy(() => import('../../../../pages/User'));
const Perfil = lazy(() => import('../../../../pages/Perfil'));
const ManageAccessProfile = lazy(() => import('../../../../pages/ManageAccessProfile'));
const ManageAccessUser = lazy(() => import('../../../../pages/ManageAccessUser'));
const Areas = lazy(() => import('../../../../pages/ManageAreas'));
const Products = lazy(() => import('../../../../pages/Products'));
// const Alerts = lazy(() => import('../../../../pages/Alerts'));
const News = lazy(() => import('../../../../pages/News'));
const Report = lazy(() => import('../../../../pages/Report'));
const MyJobsMore = lazy(() => import('../../../../pages/MyJobsMore'));
// const TwoFactor = lazy(() => import('../../../../pages/TwoFactor'));
// const NewPassword = lazy(() => import('../../../../pages/NewPassword'));
// const ResetPassword = lazy(() => import('../../../../pages/ResetPassword'));

const baseRoute = '/artwork';

const artworkRoutes = [
  { path: `${baseRoute}/repository`, component: withSuspenseFallback(Repository) },
  { path: `${baseRoute}/repository/:productPath`, component: withSuspenseFallback(PdfView) },
  {
    path: `${baseRoute}/projects/:projetoid/timeline/annex/:moduloanexonome`,
    component: withSuspenseFallback(PdfView),
  },
  // { path: `${baseRoute}/registernew`, component: withSuspenseFallback(RegisterNew) },
  { path: `${baseRoute}/projects`, component: withSuspenseFallback(Projects) },
  { path: `${baseRoute}/projects/:projetoid/workflow`, component: withSuspenseFallback(Workflow) },
  { path: `${baseRoute}/projects/:projetoid/timeline`, component: withSuspenseFallback(Timeline) },
  // { path: `${baseRoute}/config`, component: withSuspenseFallback(Config) },
  { path: `${baseRoute}/tags`, component: withSuspenseFallback(Tags) },
  // { path: `${baseRoute}/user`, component: withSuspenseFallback(User) },
  { path: `${baseRoute}/perfil/:id`, component: withSuspenseFallback(Perfil) },
  { path: `${baseRoute}/managerprofile`, component: withSuspenseFallback(ManageAccessProfile) },
  { path: `${baseRoute}/manageruser`, component: withSuspenseFallback(ManageAccessUser) },
  { path: `${baseRoute}/manageareas`, component: withSuspenseFallback(Areas) },
  { path: `${baseRoute}/products`, component: withSuspenseFallback(Products) },
  // { path: `${baseRoute}/alerts`, component: withSuspenseFallback(Alerts) },
  { path: `${baseRoute}/news`, component: withSuspenseFallback(News) },
  { path: `${baseRoute}/report`, component: withSuspenseFallback(Report) },
  { path: `${baseRoute}/morejobs`, component: withSuspenseFallback(MyJobsMore) },
  // { path: `${baseRoute}/twofactor`, component: withSuspenseFallback(TwoFactor) },
  // { path: `${baseRoute}/newpassword`, component: withSuspenseFallback(NewPassword) },
  // { path: `${baseRoute}/forgotpassword`, component: withSuspenseFallback(ResetPassword) },
];

export default artworkRoutes;

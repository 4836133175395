import artworkRoutes from '../features/products/artwork/routes/routes';
import pizzasRoutes from '../features/products/pizzas/routes/routes';
import pricePanelRoutes from '../features/products/price-panel/routes/routes';
import ratingSystemRoutes from '../features/products/rating-system/routes/routes';
import ticketSystemRoutes from '../features/products/ticket-system/routes/routes';

import userPermissions from './userPermissions';

const productsRoutes = [];

if (userPermissions.artwork) {
  productsRoutes.push(...artworkRoutes);
}
if (userPermissions.pizzas) {
  productsRoutes.push(...pizzasRoutes);
}
if (userPermissions.pricePanel) {
  productsRoutes.push(...pricePanelRoutes);
}
if (userPermissions.ratingSystem) {
  productsRoutes.push(...ratingSystemRoutes);
}
if (userPermissions.ticketSystem) {
  productsRoutes.push(...ticketSystemRoutes);
}

export default productsRoutes;

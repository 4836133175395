import { lazy } from 'react';
import { withSuspenseFallback } from '../../../helpers/withSuspenseFallback';

const Reports = lazy(() => import('../../rating-system/pages/ReportPage/Ratings'));

const baseRoute = '/ticket-system';

const ticketSystemRoutes = [{ path: `${baseRoute}`, component: withSuspenseFallback(Reports) }];

export default ticketSystemRoutes;

import { lazy } from 'react';
import { withSuspenseFallback } from '../../../helpers/withSuspenseFallback';

const Ratings = lazy(() => import('../pages/ReportPage/Ratings'));
// const DashBoard = lazy(() => import('../pages/DashBoard/DashBoard'));

const baseRoute = '/ratings-system';

const ratingsSystemRoutes = [
  // { path: `${baseRoute}/dashboard`, component: withSuspenseFallback(DashBoard) },
  { path: `${baseRoute}/reports`, component: withSuspenseFallback(Ratings) },
];

export default ratingsSystemRoutes;

import { create } from 'zustand';
import { userStore } from './userStore';

export const usePermission = create((set, get) => ({
  user: null,
  setPermissionsNull: () => {
    set({ user: null });
  },
  getUserPermission: async () => {
    let userState = get().user;
    if (userState === null) {
      userState = await userStore.getState().getUser();
      if (userState === null) return [];
      set({ user: userState });
    }
    let permissions = [];
    userState.roles.forEach((role) => {
      const roles = [...permissions];
      permissions = roles.concat(role.permissions);
    });
    const allPermissionsName = permissions.map((perm) => perm.permissaonome);
    return allPermissionsName.filter((item, index) => allPermissionsName.indexOf(item) === index);
  },
}));

const pricePanelConfig = {
  title: 'Painel de Preços',
  path: '/products/price-panel',
  childrensub: [
    {
      title: 'Categorias',
      path: '/products/price-panel/categories',
    },
    { title: 'Produtos', path: '/products/price-panel/products' },
    { title: 'Preços', path: '/products/price-panel/prices' },
    { title: 'Terminais', path: '/products/price-panel/terminals' },
  ],
};

export default pricePanelConfig;

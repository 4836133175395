import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import api from '../service/api';

const ProtectedRoutes = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const checkAuthToken = async () => {
      const authResp = await checkTokenisValid();
      setAuth(authResp);
    };

    checkAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTokenisValid = async () => {
    const temporayToken = localStorage.getItem('tokenTemporary');
    try {
      if (temporayToken === '' || !!temporayToken) {
        const responseToken = await api.post('refreshtoken');
        if (responseToken.data.status === 'success') {
          localStorage.setItem('token', `Bearer ${responseToken.data.data.token}`);
          return true;
        }
      } else if (temporayToken.length !== '') {
        return false;
      }
    } catch (err) {
      return false;
    }
    return false;
  };

  // eslint-disable-next-line no-nested-ternary
  return auth === null ? <> </> : auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useState } from 'react';
// @mui
import { Box, Stack, Typography } from '@mui/material';
import { CLOSED_SIDEBAR_WIDTH, NAVBAR_HEIGHT } from '../theme/sizes';
import DashboardNavbar from '../layouts/NavBar/DashboardNavbar';
import DashboardSidebar from '../layouts/SideBar/DashboardSidebar';
import Scrollbar from './Scrollbar';

// ----------------------------------------------------------------------

const paddingVertical = 10;

const paddingHorizontal = 10.5;

const ContentBox = styled(Box)(({ theme, contentstyle }) => ({
  marginLeft: CLOSED_SIDEBAR_WIDTH(theme),
  marginTop: NAVBAR_HEIGHT,
  overflow: 'auto',
  paddingTop: paddingVertical,
  paddingBottom: paddingVertical,
  paddingLeft: paddingHorizontal,
  paddingRight: paddingHorizontal,
  ...contentstyle,
}));

const Overlay = styled(Box)(({ open }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: open ? 'block' : 'none',
  WebkitTransform: 'translateZ(0)',
  marginTop: NAVBAR_HEIGHT,
  zIndex: 99,
}));

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, contentstyle, titleStyle, button, ...other }, ref) => {
  const [open, setOpen] = useState(false);

  const handleSidebarToggle = () => {
    if (open) setOpen(!open);
  };

  const handleOverlayClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{`${title ? `${title} |` : ''} Dashboard Clever Systems`}</title>
        {meta}
      </Helmet>
      <Scrollbar>
        <Box ref={ref} {...other} sx={{ height: '100vh', width: '100%' }}>
          <DashboardNavbar />
          <DashboardSidebar open={open} setOpen={setOpen} />

          <ContentBox open={open} contentstyle={contentstyle} onClick={handleSidebarToggle}>
            {(title || button) && (
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5} sx={titleStyle}>
                {title && (
                  <Typography variant="h4" gutterBottom>
                    {title}
                  </Typography>
                )}
                {button && button}
              </Stack>
            )}
            {children}
          </ContentBox>
          {open && <Overlay open={open} onClick={handleOverlayClick} />}
        </Box>
      </Scrollbar>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;

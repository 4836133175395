// PÁGINAS DE LOGIN

import { Suspense, lazy } from 'react';
import BaseLayout from '../layouts/BaseLayout';

// LOGIN
const Login = lazy(() => import('../pages/Login'));
const TwoFactor = lazy(() => import('../pages/TwoFactor'));
const NewPassword = lazy(() => import('../pages/NewPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

// PÁGINAS DE ADMIN
const ManageAccess = lazy(() => import('../pages/Admin/ManageAccess/ManageAccess'));
const Config = lazy(() => import('../pages/Admin/Config'));
const Perfil = lazy(() => import('../pages/Perfil'));
const Users = lazy(() => import('../pages/User'));

export const loginRoutes = [
  {
    path: '/login',
    component: (
      <Suspense>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/twofactor',
    component: (
      <Suspense>
        <TwoFactor />
      </Suspense>
    ),
  },
  {
    path: '/newpassword',
    component: (
      <Suspense>
        <NewPassword />
      </Suspense>
    ),
  },
  {
    path: '/forgotpassword',
    component: (
      <Suspense>
        <ResetPassword />
      </Suspense>
    ),
  },
];

export const adminRoutes = [
  {
    path: '/users',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <Users />
      </Suspense>
    ),
  },
  {
    path: '/config',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <Config />
      </Suspense>
    ),
  },
  {
    path: '/perfil/:id',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <Perfil />
      </Suspense>
    ),
  },
  {
    path: '/manage-access',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <ManageAccess />
      </Suspense>
    ),
  },
];

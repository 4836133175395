// material
import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box, Tooltip } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { DarkModeContext } from '../../theme/index';
import { NAVBAR_HEIGHT } from '../../theme/sizes';
// import NavWizard from './nav-wizard/NavWizard';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;

const RootStyle = styled(AppBar)(({ theme }) => ({
  zIndex: 100,
  width: '100%',
  borderBottomWidth: 0.1,
  borderBottomColor: theme.palette.divider,
  borderBottomStyle: 'solid',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
}));

const ToolbarStyle = styled(Toolbar)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  width: '100%',
  height: NAVBAR_HEIGHT,
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const darkModeContext = useContext(DarkModeContext);
  const { darkMode, setdarkMode } = darkModeContext;

  const { t } = useTranslation();

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ gridColumn: '1', width: 'max-content', paddingLeft: 2 }}>
          <Logo />
        </Box>
        {/* <Box sx={{ gridColumn: '2', display: 'flex', justifyContent: 'center' }}>
          <NavWizard />
        </Box> */}
        <Box sx={{ gridColumn: '3', display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <LanguagePopover />
            <Tooltip disableInteractive title={darkMode ? t('light-mode') : t('dark-mode')}>
              <IconButton
                sx={{ color: 'text.secondary', padding: 0, marginRight: 1 }}
                onClick={() => {
                  setdarkMode(!darkMode);
                  localStorage.setItem('darkMode', Boolean(!darkMode));
                }}
              >
                {darkMode === true ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
            <AccountPopover />
          </Stack>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}

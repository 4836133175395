import { Icon } from '@iconify/react';
import i18next from 'i18next';

import homeIcon from '@iconify/icons-material-symbols/home';
import myJobsIcon from '@iconify/icons-mdi/briefcase';
import repositoryIcon from '@iconify/icons-mdi/archive';
import tagIcon from '@iconify/icons-mdi/tag';
import productsIcon from '@iconify/icons-eva/shopping-bag-fill';
import projectsIcon from '@iconify/icons-mdi/sitemap';
import areasIcon from '@iconify/icons-bx/area';
import userAccountIcon from '@iconify/icons-mdi/account-box-multiple';
import usersIcon from '@iconify/icons-eva/people-fill';
import settingsIcon from '@iconify/icons-eva/settings-2-fill';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const artworkConfig = {
  title: 'Artwork',
  path: '/products/artwork',
  childrensub: [
    {
      title: i18next.t('sidebar.home'),
      path: '/products/artwork/dashboard/app',
      icon: getIcon(homeIcon),
    },
    {
      title: i18next.t('sidebar.my-jobs'),
      path: '/products/artwork/morejobs',
      icon: getIcon(myJobsIcon),
    },
    {
      title: i18next.t('sidebar.repo'),
      path: '/products/artwork/repository',
      icon: getIcon(repositoryIcon),
    },
    {
      title: i18next.t('sidebar.tag'),
      path: '/products/artwork/tag',
      icon: getIcon(tagIcon),
    },
    {
      title: i18next.t('sidebar.products'),
      path: '/products/artwork/products',
      icon: getIcon(productsIcon),
    },
    {
      title: i18next.t('sidebar.projects'),
      path: '/products/artwork/projects',
      icon: getIcon(projectsIcon),
    },
    {
      title: i18next.t('sidebar.areas'),
      path: '/products/artwork/manageareas',
      icon: getIcon(areasIcon),
    },
    {
      title: i18next.t('sidebar.user'),
      path: '/products/artwork/user',
      icon: getIcon(usersIcon),
    },
    {
      title: i18next.t('sidebar.manageacess'),
      path: '/products/artwork/managerprofile',
      icon: getIcon(userAccountIcon),
    },
    {
      title: i18next.t('sidebar.report'),
      path: '/products/artwork/report',
      icon: getIcon('eva:file-text-fill'),
    },
    {
      title: i18next.t('sidebar.settings'),
      path: '/products/artwork/config',
      icon: getIcon(settingsIcon),
    },
  ],
};

export default artworkConfig;

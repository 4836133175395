import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import logoDark from '../assets/logos-clever/site-logo-dark.png';
import logoLight from '../assets/logos-clever/site-logo-light.png';

// ----------------------------------------------------------------------

const ImgBoxStyle = styled(Box)(() => ({
  width: 200,
  marginLeft: 10,
  padding: 10,
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
export default function Logo({ sx }) {
  const theme = useTheme();

  return (
    <Link to="/dashboard">
      <ImgBoxStyle
        component="img"
        src={theme.palette.mode === 'light' ? logoDark : logoLight}
        width={200}
        height={71}
      />
    </Link>
  );
}

Logo.propTypes = {
  sx: PropTypes.any,
};

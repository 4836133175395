import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Badge } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// components
import MenuPopover from '../../components/MenuPopover';
import brasil from '../../assets/flags/bandeira-brasil.svg';
import eua from '../../assets/flags/bandeira-eua.svg';
import espanha from '../../assets/flags/bandeira-espanha.svg';
import notation from '../../assets/icones/icon-notification2.svg';

// api
// import api from '../../service/api';

const languangeOptions = [
  {
    value: 'pt',
    label: 'Português',
    icon: brasil,
  },
  {
    value: 'en',
    label: 'Inglês',
    icon: eua,
  },
  {
    value: 'es',
    label: 'Español',
    icon: espanha,
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { i18n } = useTranslation();
  // const [countAlert, setCountAlert] = useState({ total: 0 });
  const [selectedLanguage, setSelectedLanguage] = useState(
    languangeOptions.find((option) => option.value === i18n.language)
  );

  // async function requestAlertCounters() {
  //   try {
  //     // eslint-disable-next-line dot-notation
  //     api.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  //     const response = await api.get('/alertcounter');
  //     // eslint-disable-next-line prefer-destructuring

  //     if (response.status === 200) {
  //       const contador = response.data.data.alertcounter;
  //       setCountAlert(contador);
  //     } else {
  //       console.log('erro');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   requestAlertCounters();
  // }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenAlert = () => {
    navigate('/alerts', { replace: true });
  };

  const selectLanguage = (option) => {
    i18n.changeLanguage(option.value);
    setSelectedLanguage(option);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={selectedLanguage.icon} alt={selectedLanguage.label} height={30} width={30} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {languangeOptions.map((option) => (
            <MenuItem key={option.value} selected={option.value === 'true'} onClick={() => selectLanguage(option)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
      <Stack spacing={2} direction="row">
        <Badge badgeContent={0} color="primary" max={99}>
          {/* <Badge badgeContent={countAlert.total} color="primary" max={99}> */}
          <IconButton
            ref={anchorRef}
            onClick={handleOpenAlert}
            sx={{
              padding: 0,
              width: 30,
              height: 30,
              ...(open && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
              }),
            }}
          >
            <img src={notation} alt="Click para detalhes" />
          </IconButton>
        </Badge>
      </Stack>
    </>
  );
}
